import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import HeroBanner from '@cof/graffiti-alley-spray-cans/molecules/HeroBanner';
import Button from '@cof/graffiti-alley-spray-cans/molecules/Button';

import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';
import CardHighLights from '../components/CardHighlights';
import { superScript } from '../lib/utils';

import LegalContent from '../components/ModalContent/HomePageLegalModalContent';
import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';

import './index.scss';

const HomePage = injectIntl(({ intl }) => {
  return (
    <Layout pageName="home-page">
      <div className="home-page-body-wrapper">
        <div className="page-content-wrapper" data-for="homepage-hero">
          <HeroBanner backgroundContentClass="home-page-hero-banner" mobileFriendly>
            <p className="as-h1">
              <FormattedMessage
                id="hero.home-page.message"
                values={{ rball: superScript('rball'), star: superScript('star'), MC: <sup>MC</sup> }}
              />
            </p>
            <Link to="/quickcheck/" className="action button see-cards-button">
              <FormattedMessage id="hero.home-page.cta" />
            </Link>
          </HeroBanner>
        </div>
        <div className="home-page-card-highlights">
          <CardHighLights className="page-content" />
        </div>
        <div className="belief-banner">
          <div className="belief-banner-wrapper">
            <h3>
              <FormattedMessage id="pages.home.belief-banner.title" />
            </h3>
            <p>
              <FormattedMessage id="pages.home.belief-banner.description" />
            </p>
            <Button
              className="belief-banner-cta"
              color="progressive"
              hollow={true}
              url={intl.formatMessage({ id: 'pages.home.belief-banner.cta-link' })}
            >
              <FormattedMessage id="pages.home.belief-banner.cta" />
            </Button>
          </div>
        </div>
        <p className="accessibility">
          <Link to="/contact-us/concerns/">
            <FormattedMessage id="pages.home.accessibility" />
          </Link>
        </p>

        <section id="home-page-legal" className=" home-page-legal" data-for="home-page-legal">
          <ModalLink
            target={LegalContent}
            className="home-page-legal-link"
            title=" "
            linkIcon={false}
            intl={intl}
            size="large"
          >
            <FormattedMessage id="navfooter.legal.legal-updated" />
          </ModalLink>
        </section>
      </div>
    </Layout>
  );
});

export default HomePage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.home.page-title" descriptionKey="pages.home.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
